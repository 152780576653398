
import { defineComponent, Ref, ref } from 'vue'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    // const allocationRawValue = ref('')
    const cleanUser = ref(false)
    const cleanAll = ref(false)
    const allocationTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () =>
      (allocationTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      const allocationRawValue = getSelectionRaw()
      if (allocationRawValue?.length) {
        emit(EVENTS.OK, allocationRawValue, cleanUser.value, cleanAll.value)
      }
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      cleanUser,
      cleanAll,
      ok,
      cancel,
      // allocationRawValue,
      allocationTextAreaWrapper
    }
  }
})
