<template>
  <a-modal title="Batch Allocation" width="800px" :visible="isVisible" @ok="ok" @cancel="cancel">
    <textarea
      ref="allocationTextAreaWrapper"
      style="width: 100%; height: 450px"
      placeholder="Paste your allocation here (variable,username1,username2,...)"
      name="sz-allocation-textarea"
      spellcheck="false"
    />
    <a-checkbox v-model:checked="cleanUser">
      Clean existing allocations for the relevant users
    </a-checkbox>
    <a-checkbox v-model:checked="cleanAll">Clean all existing allocations</a-checkbox>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from 'vue'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    // const allocationRawValue = ref('')
    const cleanUser = ref(false)
    const cleanAll = ref(false)
    const allocationTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () =>
      (allocationTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      const allocationRawValue = getSelectionRaw()
      if (allocationRawValue?.length) {
        emit(EVENTS.OK, allocationRawValue, cleanUser.value, cleanAll.value)
      }
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      cleanUser,
      cleanAll,
      ok,
      cancel,
      // allocationRawValue,
      allocationTextAreaWrapper
    }
  }
})
</script>
