import { render } from "./AllocationPage.vue?vue&type=template&id=c7078ab8"
import script from "./AllocationPage.vue?vue&type=script&lang=ts"
export * from "./AllocationPage.vue?vue&type=script&lang=ts"

import "./AllocationPage.vue?vue&type=style&index=0&id=c7078ab8&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c7078ab8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c7078ab8', script)) {
    api.reload('c7078ab8', script)
  }
  
  module.hot.accept("./AllocationPage.vue?vue&type=template&id=c7078ab8", () => {
    api.rerender('c7078ab8', render)
  })

}

script.__file = "src/components/surveyAllocation/AllocationPage.vue"

export default script