import { keyBy } from 'lodash-es'
import { isEmpty, isNil } from 'ramda'
import { computed, reactive, Ref, ref, watch } from 'vue'
import { useRequest } from 'vue-request'

import { DB_FIELDS } from '@/constants/database'
import { Network, Variable } from '@/libs/bayes'
import { Dict } from '@/libs/common'
import { logger } from '@/libs/logger'
import {
  createAllocation,
  deleteAllocation,
  // getAllocation,
  getAllocations,
  updateAllocation
} from '@/services/api/allocation'
import { Store } from '@/store'
import { Allocation, AllocationForm, Assignment, User, Workspace } from '@/types'

const {
  ASSIGNMENTS,
  ASSIGNMENTS_ASSIGNED,
  ASSIGNMENTS_EXT,
  ASSIGNMENTS_VARIABLE_ID,
  NETWORK_ID,
  USER_ID,
  WORKSPACE_ID
} = DB_FIELDS.ALLOCATION

export const parseAllocationRaw = (
  rawValue: string,
  userMapByName: Dict,
  variableMapByKey: Dict
): Dict => {
  /* 
  - variable name, e.g. fp1
  - username, e.g. user1,user2
  */
  const rows = rawValue.split(/\r?\n/)
  const mapByUser: Dict = {}
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    const cols = row.split(/\s*,\s*/)
    const key = cols[0]
    const usernames = cols.slice(1)
    const variable = variableMapByKey[key]
    if (!variable) {
      continue
    }
    usernames.forEach((username) => {
      const userId = userMapByName[username]?.id
      if (!userId) {
        return
      }
      if (userId in mapByUser) {
        mapByUser[userId].push(variable.id)
      } else {
        mapByUser[userId] = [variable.id]
      }
    })
  }
  return mapByUser
}

export default function useAllocation(
  store: Store,
  workspaceId: Workspace['id'],
  networkId: Network['id']
): any {
  const allocationMap: any = reactive({})
  const { run: runGetAllocations, data: dataAllocations } = useRequest(getAllocations, {
    manual: true
  })

  const allocations: Ref<Allocation[]> = ref([])
  const refreshAllocations = () => {
    allocations.value = dataAllocations.value?.content || []
  }

  watch(dataAllocations, () => {
    refreshAllocations()
    updateAllocationMap()
  })

  const getAllAllocations = async () => {
    runGetAllocations(workspaceId)
  }

  const allocationByUserId: any = computed((): any => keyBy(allocations.value, 'userId') || {})

  const updateAllocationMap = () => {
    const map: Dict = {}
    allocations.value?.forEach((allocation: any) => {
      const userId = allocation[DB_FIELDS.ALLOCATION.USER_ID]
      const userMap: Dict = {}
      allocation?.assignments?.forEach((assignment: any) => {
        const variableId = assignment[DB_FIELDS.ALLOCATION.ASSIGNMENTS_VARIABLE_ID]
        const assigned = assignment[DB_FIELDS.ALLOCATION.ASSIGNMENTS_ASSIGNED] || false
        if (assigned) {
          userMap[variableId] = assigned
        }
      })
      map[userId] = userMap
    })
    Object.assign(allocationMap, map)
  }

  const isFixing: Ref<boolean> = ref(false)
  const fixAllocation = async (): Promise<any> => {
    isFixing.value = true
    refreshAllocations()
    const allocsByUserId: Dict = {}
    allocations.value.forEach((allocation: Allocation) => {
      const { userId } = allocation
      if (allocsByUserId[userId]) {
        allocsByUserId[userId].push(allocation)
      } else {
        allocsByUserId[userId] = [allocation]
      }
    })
    const promises: Promise<any>[] = []
    Object.keys(allocsByUserId).forEach((userId: string) => {
      const allocs = allocsByUserId[userId]
      const allocCount = allocs.length
      if (allocCount > 1) {
        const fixedAllocation = allocs[0]
        const fixedAssignments = fixedAllocation.assignments
        const fixedAssignmentMap = keyBy(fixedAssignments, 'variableId')
        for (let i = 1; i < allocCount; i++) {
          const deletedAlloc = allocs[i]
          deletedAlloc.assignments?.forEach((assignment: Assignment) => {
            if (!(assignment.variableId in fixedAssignmentMap)) {
              fixedAssignments.push(assignment)
              fixedAssignmentMap[assignment.variableId] = assignment
            }
          })
          promises.push(deleteAllocation(allocs[i].id))
        }
        fixedAllocation.assignments = fixedAssignments
        promises.push(updateAllocation({ id: fixedAllocation.id, allocation: fixedAllocation }))
      }
    })
    return Promise.all(promises).then(() => {
      isFixing.value = false
    })
  }

  const allocateUserToVariable = async (
    user: User,
    variableIdMap: Dict,
    variables: Array<Variable>,
    cleanExisting = false
  ): Promise<any> => {
    const userId = user.id

    if (!userId || !networkId) {
      return
    }
    const allocation: any = allocationByUserId.value[user.id]

    // If current user already has allocation, then update allocation or create new one
    if (allocation) {
      if (allocation.networkId !== networkId) {
        console.error('Mismatched network id for allocation', {
          origin: allocation.networkId,
          passed: networkId
        })
      } else {
        const allocationId = allocation.id
        const newAssignments: any[] = []
        const assignmentMap = keyBy(allocation.assignments, 'variableId')
        allocation.assignments?.forEach((assignment: Assignment) => {
          // push existing ones
          if (assignment.variableId in variableIdMap) {
            if (variableIdMap[assignment.variableId]) {
              newAssignments.push({
                ...assignment,
                [ASSIGNMENTS_ASSIGNED]: variableIdMap[assignment.variableId]
              })
            }
          } else if (assignment.assigned && !cleanExisting) {
            newAssignments.push(assignment)
          }
        })
        Object.keys(variableIdMap).forEach((varId: string) => {
          // push new ones
          if (!(varId in assignmentMap)) {
            newAssignments.push({
              [ASSIGNMENTS_ASSIGNED]: variableIdMap[varId],
              [ASSIGNMENTS_EXT]: {},
              [ASSIGNMENTS_VARIABLE_ID]: varId
            })
          }
        })
        const newAllocation: AllocationForm = {
          ...allocation,
          [ASSIGNMENTS]: newAssignments
        }
        allocation.assignments = newAssignments
        return updateAllocation({ id: allocationId, allocation: newAllocation })
      }
    } else {
      if (!isNil(variables) && !isEmpty(variables) && !isEmpty(variableIdMap)) {
        // If clicked var is equal to current var, then make assigned as true
        const assignments: any[] = []
        Object.keys(variableIdMap).forEach((varId: string) => {
          if (variableIdMap[varId]) {
            assignments.push({
              [ASSIGNMENTS_ASSIGNED]: true,
              [ASSIGNMENTS_EXT]: {},
              [ASSIGNMENTS_VARIABLE_ID]: varId
            })
          }
        })
        const newAllocation = {
          [ASSIGNMENTS]: assignments,
          [NETWORK_ID]: networkId,
          [USER_ID]: userId,
          [WORKSPACE_ID]: workspaceId
        } as AllocationForm
        return createAllocation({
          allocation: newAllocation,
          workspaceId
        })
      }
    }
    if (allocation) {
      return Promise.resolve(allocation)
    }
  }

  const updateAllocationsFromResults = (results: any[]) => {
    results?.forEach((result) => {
      if (!result) {
        return
      }
      const userId = result.userId

      if (userId && result.assignments) {
        logger.info('Update allocation from results')
        if (userId in allocationByUserId.value) {
          Object.assign(allocationByUserId.value[userId], result)
        } else {
          allocations.value.push(result)
        }
      }
    })
  }

  return {
    isFixing,
    fixAllocation,
    updateAllocationsFromResults,
    allocationMap,
    allocations,
    updateAllocationMap,
    getAllAllocations,
    allocateUserToVariable
  }
}
