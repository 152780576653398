
import { CheckOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { FileImport, Wand } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message } from 'ant-design-vue'
import { find, isEmpty, keyBy } from 'lodash-es'
import { includes } from 'ramda'
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  onUpdated,
  PropType,
  Ref,
  ref,
  watch
} from 'vue'

import useAllocation, { parseAllocationRaw } from '@/components/composables/allocation-next'
import {
  COLUMNS,
  TableConfig
  // VAR_NAME_MIN_WIDTH,
  // VAR_NAME_PAD
} from '@/components/variable/variable-table'
import VariableNameCol, { EVENTS as VAR_EVENTS } from '@/components/variable/VariableNameCol.vue'
import { API_DEFAULT_PAGEABLE_PARAMS } from '@/constants/api'
import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { ModuleNames } from '@/constants/vuex'
import { Network, Variable } from '@/libs/bayes'
import { VariableRelation } from '@/libs/bayes/enums/VariableRelation'
import { Dict } from '@/libs/common'
import { syncScrolls } from '@/libs/utils'
import router from '@/router'
import {
  initSurveyStatusesForUsers,
  updateSurveyStatusForUser
} from '@/services/composition/survey'
import { useStore } from '@/store'
import { UserActionEnum } from '@/store/enums/actions'
import { UserStateEnum } from '@/store/enums/states'
import { vuexActions } from '@/store/util'
import { cssIcon } from '@/styles/common'
import { User } from '@/types'

import AllocationBatchImport, { EVENTS as IMPORT_EVENTS } from './AllocationBatchImport.vue'

// https://bitbucket.eresearch.unimelb.edu.au/projects/SAUC/repos/frontend/browse/src/components/variable/VariableTable.vue?at=4a718e803903d34a8e79e55eb40b747279005705
export default defineComponent({
  components: {
    CheckOutlined,
    FileImport,
    Icon,
    SearchOutlined,
    VariableNameCol,
    AllocationBatchImport,
    Wand
  },
  props: {
    network: { type: Object as PropType<Network>, required: true },
    selectedVariable: { type: Object as PropType<Variable>, default: undefined },
    currentSurvey: { type: Object, default: undefined },
    analyticsMap: { type: Object, default: undefined },
    config: {
      type: Object as PropType<TableConfig>,
      required: false,
      default: () => ({
        analyticsVisible: false,
        actionVisible: false,
        allocationVisible: true,
        collectionMethodVisible: false,
        dependencyVisible: true,
        indexVisible: true,
        statesVisible: true,
        variableNameVisible: true
      })
    },
    showAux: { type: Boolean, default: true },
    readOnly: { type: Boolean, default: false }
  },
  emits: [EMIT_EVENTS.VARIABLE.SELECT, EMIT_EVENTS.VARIABLE.CHANGE],
  setup(props, context) {
    const isBatchImportVisible: Ref<boolean> = ref(false)
    const store = useStore()
    const editedVariable: Ref<Variable | undefined> = ref()
    const variableEditorVisible = ref(false)
    const routerParams = router.currentRoute.value.params
    let { workspaceId: routerWorkspaceId } = routerParams
    let workspaceId: string

    if (Array.isArray(routerWorkspaceId)) {
      workspaceId = routerWorkspaceId[0]
    } else {
      workspaceId = routerWorkspaceId
    }
    const parents = computed(() =>
      props.network && props.selectedVariable
        ? props.network.getParents(props.selectedVariable)
        : []
    )
    const children = computed(() =>
      props.network && props.selectedVariable
        ? props.network.getChildren(props.selectedVariable)
        : []
    )
    const variables = computed(() => (props.network ? props.network.variables : []))

    // const networkListContent = computed(
    //   () => store.state[ModuleNames.NETWORK][NetworkStateEnum.NETWORK_LIST]?.content
    // )
    // const networkId = computed(() => {
    //   const currentNetwork = networkListContent.value[0]
    //   const netId = currentNetwork?.id
    //   if (netId && typeof netId === 'string') {
    //     return netId
    //   }
    //   return null
    // })
    const totalUsers = computed(() => {
      return store.state[ModuleNames.USER][UserStateEnum.USER_LIST]?.totalElements
    })
    // const allocations = computed(
    //   () => store.state[ModuleNames.ALLOCATION][AllocationStateEnum.ALLOCATION_LIST]?.content
    // )

    const userList: ComputedRef<User[]> = computed(
      () => store.state.user[UserStateEnum.USER_LIST]?.content
    )
    const userMap = computed(() => {
      const result: Record<string, User> = {}
      userList.value?.forEach((each: User) => (result[each.id] = each))
      return result
    })
    const userMapByName = computed(() => {
      const result: Record<string, User> = {}
      userList.value?.forEach((each: User) => (result[each.username] = each))
      return result
    })

    const allocatedUsers: ComputedRef<User[]> = computed(() =>
      userList.value.filter(
        (eachUser: User): boolean =>
          !includes(DB_ENUM_VALUES.USER.ROLES.ADMIN, eachUser[DB_FIELDS.USER.ROLES])
      )
    )

    // const state = reactive({
    //   searchText: '',
    //   searchedColumn: ''
    // })
    const searchText = ref('')
    const searchedColumn = ref('')

    const userSearchInput = ref<string>('')
    const variableRegexInput = ref<string>('')
    const tableSearchInput = ref()
    const variableTableWrapper = ref(null)
    // const maxVariableWidth = computed(() => {
    //   const variableNameWidth = Math.max(
    //     Math.max(...data.value.map((row: RowData) => row.variableNameWidth)) + VAR_NAME_PAD,
    //     VAR_NAME_MIN_WIDTH
    //   )
    //   return `${variableNameWidth}px`
    // })

    const {
      isFixing,
      fixAllocation,
      allocationMap,
      allocations,
      updateAllocationsFromResults,
      getAllAllocations,
      allocateUserToVariable
    } = useAllocation(store, workspaceId, props.network.id)

    const data: ComputedRef<Dict[]> = computed(() => {
      return (variables as any).value.map((eachVar: Variable, idx: number) => {
        const data: Dict = {}
        data.key = idx
        data[COLUMNS.VAR_INDEX.DATA_INDEX] = idx + 1
        data[COLUMNS.VAR_NAME.DATA_INDEX] = eachVar.name

        data.variable = eachVar
        // data.variableNameWidth = getColumnWidth(eachVar.name)
        // data.stateNameWidth = getColumnWidth(
        //   eachVar.states.map((each) => each.name).join(' '),
        //   true
        // )
        userList.value?.forEach((eachUser: User) => {
          data[eachUser.id] = {
            user: eachUser,
            assigned: allocationMap?.[eachUser.id]?.[eachVar.id] || false
          }
        })
        return data
      })
    })

    const dataMap = computed(() => keyBy(data.value, 'id'))

    /**
     * Handle table search
     **/
    const handleSearch = (selectedKeys: Array<any>, confirm: any, dataIndex: string) => {
      confirm()
      searchText.value = selectedKeys[0]
      searchedColumn.value = dataIndex
    }

    /**
     * Handle reset table search
     **/
    const handleReset = (clearFilters: any) => {
      clearFilters()
      searchText.value = ''
    }

    /**
     * Update selected variable's parent and child
     **/
    const updateRelations = () => {
      return (variables as any).value.map((variable: any) =>
        includes(variable, (parents as any).value)
          ? VariableRelation.PARENT
          : includes(variable, (children as any).value)
          ? VariableRelation.CHILD
          : props.selectedVariable?.id === variable.id
          ? VariableRelation.SELECTED
          : VariableRelation.NONE
      )
    }

    const relations = ref(updateRelations())

    watch(
      () => props.network,
      (): void => {
        relations.value = updateRelations()
      }
    )

    watch(
      () => props.selectedVariable,
      (): void => {
        relations.value = updateRelations()
      }
    )

    /**
     * Check whether current user assigned or not
     **/
    const isAssigned = (user: User, variable: Variable) => {
      if (user) {
        const allocation: any = find(allocations.value, {
          [DB_FIELDS.ALLOCATION.USER_ID]: user.id
        })
        const assignments = allocation?.assignments
        return (
          find(assignments, {
            [DB_FIELDS.ALLOCATION.ASSIGNMENTS_VARIABLE_ID]: variable?.id
          })?.assigned || false
        )
      }
    }

    const handleBulkAllocation = async () => {
      const userName = userSearchInput.value
      const regex = variableRegexInput.value
      if (isEmpty(regex) || isEmpty(userName)) {
        return
      }
      const user = userMapByName.value[userName]
      if (!user) {
        return
      }
      // const candidateVariables: any[] = []
      // variables.value.forEach((variable: Variable) => {
      //   if (variable.key.match(regex)) {
      //     candidateVariables.push(variable)
      //   }
      // })
      // candidateVariables.forEach(async (variable_: Variable) => {
      // await handleClickAllocation(user, variable)
      // })
    }

    /**
     * Handle search user
     * @param input
     */
    const handleUserSearch = async (input: string) => {
      await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
        params: { [DB_FIELDS.USER.USERNAME]: input.trim() }
      })
    }

    const nodeChange = (nodeDefinition: number[]) => {
      if (editedVariable.value) {
        editedVariable.value.nodeDefinition = nodeDefinition
      }
    }

    onMounted(async () => {
      syncScrolls('var-table')
      await getAllAllocations()
    })

    // watch(allocations, (): void => {
    //   console.log('allocations updated')
    //   // updateAllocationMap()
    // })

    onUpdated(() => {
      syncScrolls('var-table')
    })

    const selectVariable = (variable: Variable) => {
      context.emit(EMIT_EVENTS.VARIABLE.SELECT, variable)
    }

    /**
     * Handle click allocation
     * @param user
     * @param variable
     **/
    const handleClickAllocation = async (row: Dict, user: User, variable: Variable) => {
      if (!workspaceId) {
        return
      }
      if (!allocationMap[user.id]) {
        allocationMap[user.id] = {}
      }
      const newAssigned = !(allocationMap[user.id][variable.id] || false)
      allocationMap[user.id][variable.id] = newAssigned
      if (row) {
        row[user.id] = {
          user,
          assigned: newAssigned
        }
      }
      const newAllocation = await allocateUserToVariable(
        user,
        { [variable.id]: newAssigned },
        variables.value,
        false
      )
      updateAllocationsFromResults([newAllocation])
      updateSurveyStatusForUser(workspaceId, user.id, newAllocation.assignments?.length > 0)
    }

    const importBulk = async (text: string, cleanUser: boolean, cleanAll: boolean) => {
      isBatchImportVisible.value = false
      const allocMapByUser: Dict = parseAllocationRaw(
        text,
        userMapByName.value,
        props.network.variableMapByKey
      )
      const promises: Promise<any>[] = []
      const userIdsForSurveyStatus: string[] = []
      Object.keys(allocMapByUser).forEach((userId: string) => {
        const user = userMap.value[userId]
        userIdsForSurveyStatus.push(userId)
        if (!allocationMap[userId] || cleanUser) {
          allocationMap[userId] = {}
        }
        const varIds = allocMapByUser[userId]
        const varIdMap: Dict = {}
        varIds.forEach((varId: string) => {
          // const k = props.network.variableMap[varId].key
          allocationMap[userId][varId] = true
          varIdMap[varId] = true
          const row = dataMap.value[varId]
          if (row) {
            row[userId] = {
              user,
              assigned: true
            }
          }
        })
        const promise = allocateUserToVariable(user, varIdMap, variables.value, cleanUser)
        promises.push(promise)
      })
      if (cleanAll) {
        Object.keys(userMap.value).forEach((userId: string) => {
          if (!(userId in allocMapByUser)) {
            const promise = allocateUserToVariable(userMap.value[userId], {}, variables.value, true)
            if (promise) {
              promises.push(promise)
            }
            if (allocationMap[userId]) {
              Object.keys(allocationMap[userId]).forEach((varId: string) => {
                allocationMap[userId][varId] = false
              })
            }
          }
        })
      }
      const results = await Promise.all(promises)
      updateAllocationsFromResults(results)
      initSurveyStatusesForUsers(workspaceId, userIdsForSurveyStatus)
    }

    const importBatch = () => {
      isBatchImportVisible.value = true
    }

    const doFixAllocation = () => {
      message.info('Fixing allocation')
      setTimeout(() => {
        fixAllocation()
      }, 100)
    }

    watch(isFixing, (newValue: boolean, oldValue: boolean) => {
      if (oldValue === true && newValue === false) {
        message.destroy()
      }
    })

    return {
      importBulk,
      importBatch,
      isBatchImportVisible,
      IMPORT_EVENTS,
      selectVariable,
      allocatedUsers,
      nodeChange,
      EMIT_EVENTS,
      COLUMNS,
      API_DEFAULT_PAGEABLE_PARAMS,
      userMap,
      userList,
      relations,
      variables,
      cssIcon,
      data,
      parents,
      variableTableWrapper,
      // maxVariableWidth,
      searchText,
      tableSearchInput,
      searchedColumn,
      userSearchInput,
      totalUsers,
      handleSearch,
      handleReset,
      VAR_EVENTS,
      isAssigned,
      editedVariable,
      variableEditorVisible,
      variableRegexInput,
      handleBulkAllocation,
      handleClickAllocation,
      handleUserSearch,
      allocations,
      allocationMap,
      doFixAllocation
    }
  }
})
