import { render } from "./VariableAllocation.vue?vue&type=template&id=88b7dade"
import script from "./VariableAllocation.vue?vue&type=script&lang=ts"
export * from "./VariableAllocation.vue?vue&type=script&lang=ts"

import "./VariableAllocation.vue?vue&type=style&index=0&id=88b7dade&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "88b7dade"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('88b7dade', script)) {
    api.reload('88b7dade', script)
  }
  
  module.hot.accept("./VariableAllocation.vue?vue&type=template&id=88b7dade", () => {
    api.rerender('88b7dade', render)
  })

}

script.__file = "src/components/surveyAllocation/VariableAllocation.vue"

export default script