import { render } from "./AllocationNewView.vue?vue&type=template&id=60c5221b"
import script from "./AllocationNewView.vue?vue&type=script&lang=ts"
export * from "./AllocationNewView.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "60c5221b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('60c5221b', script)) {
    api.reload('60c5221b', script)
  }
  
  module.hot.accept("./AllocationNewView.vue?vue&type=template&id=60c5221b", () => {
    api.rerender('60c5221b', render)
  })

}

script.__file = "src/views/AllocationNewView.vue"

export default script